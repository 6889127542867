<template>
  <div class="apartment-list">
    <app-list :opt="opt" @get="onGet"></app-list>
  </div>
</template>
<script>
  import Vue from "vue";
  let userInfo;
  const TYPE = {
    1: '公寓',
    2: '小区',
    3: '酒店',
    4: '民宿',
    5: '别墅',
    6: '工业园',
    7: '学校',
  };
  export default {
    data() {
      let _this = this;
      userInfo = this.cache.getLS("userInfo");
      return {
        opt: {
          title: "房源列表",
          search: [{
              label: "省份",
              key: "province",
              type: "selectCity",
              opt: {
                list: [],
                change(data, cb) {
                  _this.provinceVal = data;
                  _this.opt.search.forEach((item, index) => {
                    if (item.key == "city" || item.key == "apartmentId") {
                      cb({
                        value: "",
                        key: item.key
                      });
                    item.opt.list = [];
                    }
                  });
                  //清空省份 不请求城市数据
                  if(data) {
                    _this.getCityList(data, '');
                  }
                  _this.getApartmentList('',data);
                }
              }
            },
            {
              label: "城市",
              key: "city",
              type: "select-remote-filterable",
              opt: {
                list: [],
                multiple: false,
                on(data, cb) {
                  if (data) {
                    _this.getCityList(_this.provinceVal, data);
                    _this.opt.search.forEach((item, index) => {
                      if (item.key == "city") {
                        cb(item.opt.list);
                      }
                    });
                  }
                },
                change(data, cb) {
                  _this.getApartmentList(data,'');
                  _this.opt.search.forEach((item, index) => {
                    if (item.key == "apartmentId") {
                      cb({
                        value: "",
                        key: item.key
                      });
                    }
                    //清空城市后 初始化城市列表
                    if(item.key == "city") {
                      if(!data && !_this.provinceVal) {
                        item.opt.list = [];
                      }
                    }
                  });
                },
              },
            },
            {
              key: "apartmentId",
              label: "房源",
              type: "selectCity",
              opt: {
                list: []
              }
            },

          ],
          columns: [{
              label: "房源名称",
              key: "name",
              on: row => {
                if(row.type == 7) {
                  _this.$router.push({
                    path: "/main/apartment/dyn-config-apartmentDetail/show/" + row.id,
                    query: {
                      type: row.type
                    }
                  })
                }else {
                  _this.$router.push("/main/apartment/apartmentDetail/show/" + row.id);
                }
              }
            },
            {
              label: "房源类型",
              key: "typeStr"
            },
            {
              label: "房东姓名",
              key: "landlordName"
            },
            {
              label: "楼栋数",
              key: "buildings"
            },
            {
              label: "房间数",
              key: "rooms"
            },
            {
              label: "城市",
              key: "cityStr"
            },
            {
              label: "详细地址",
              key: "address"
            },
            {
              label: "操作",
              key: "miniTwoCodeUrl",
              type: "action-but",
              opt: {
                list: [{
                  label: "查看房源二维码",
                  on(row) {
                    let hostname = Vue.config.BASE_URL;
                    for (let i = 0; i < Vue.config.urlOpt.length; i++) {
                      if (hostname == Vue.config.urlOpt[i][0]) {
                        hostname = Vue.config.urlOpt[i][1];
                        break;
                      }
                    }
                    let _text = `${hostname}/wechatsweepcode/wave?id=${row.id}&type=1`;

                    console.log(_text);
                    _this
                      .post(
                        "/tenant-service/utils/generateQrCode", {
                          text: _text
                        }, {
                          isShowLoading: true
                        }
                      )
                      .then(data => {
                        if (data) {
                          const base64ImgUrl =
                            "data:image/png;base64," + data.result;

                          _this.$alert(
                            `<img style="display: block;margin: 0 auto;" src="${base64ImgUrl}">`,
                            "查看房源二维码图片", {
                              dangerouslyUseHTMLString: true
                              // showCancelButton: true,
                              // cancelButtonText: "下载二维码",
                              // callback: action => {
                              //   if (action == "cancel") {
                              //     window.open(url2);
                              //   }
                              // }
                            }
                          );
                        }
                      });
                  }
                }]
              }
            }
          ],
          buttons: [{
              type: 0,
              on() {
                if(_this.getSchoolLandlordFlag()) {
                   _this.$router.push("/main/apartment/dyn-config-apartmentDetail/add/0");
                }else {
                  _this.$router.push("/main/apartment/apartmentDetail/add/0");
                }
              }
            },
            {
              type: 1,
              on(data) {
                if(data.data.type == 7) {
                   _this.$router.push({
                    path: "/main/apartment/dyn-config-apartmentDetail/edit/" + data.id,
                    query: {
                      type: data.data.type
                    }
                   })
                }else {
                  _this.$router.push("/main/apartment/apartmentDetail/edit/" + data.id);
                }
              }
            },
            {
              type: 2,
              on(data) {
                if (data.ids.length > 1) {
                  return _this.$message({
                    showClose: true,
                    message: "不支持多个删除,只支持单个删除!",
                    type: "warning"
                  });
                }
                const h = _this.$createElement;
                _this
                  .$msgbox({
                    title: "删除提示",
                    message: h("p", null, [
                      h(
                        "span", {
                          style: "color: #333;font-size: 16px;margin-left:98px"
                        },
                        "确定要删除房源吗？"
                      ),
                      h(
                        "p", {
                          style: "font-size:14px;margin-left:98px"
                        },
                        "删除房源后，房源下的房型、房间都将被删除"
                      )
                    ]),
                    showCancelButton: true,
                    confirmButtonText: "确定",
                    cancelButtonText: "取消"
                  })
                  .then(action => {
                    var dto = {
                      id: data.ids[0],
                      name:data.list[0].name
                    };
                    _this
                      .post("landlord-service/apartment/delete", dto)
                      .then(function () {
                        _this.$message({
                          showClose: true,
                          message: "删除房源成功！",
                          type: "success"
                        });
                        data.refresh();
                      });
                  });
              }
            }
          ]
        },
        apartmentList: [],
        provinceVal: '',
        
      };
    },
    created() {
      console.log("apartment-list created!!");
    },
    activated() {
      let mySearchForm = [];
      this.opt.search.forEach(item => {
        mySearchForm.push(item.key);
      })
      // 房东和房管员不显示房东的检索条件
      if (userInfo.roleType == 2) {
        this.opt.search.forEach(item => {
          if (item.key == "landlordId") {
            this.opt.search.splice(index, 1);
          }
        })
      } else {
        if (mySearchForm.indexOf("landlordId") == -1) {
          this.opt.search.push({
            key: "landlordId",
            label: "房东",
            type: "select",
            opt: {
              list: []
            }
          })
        }
      }
      // 学校房东不展示房源二维码
      if(this.getSchoolLandlordFlag()) {
        this.opt.columns.map((item,index) => {
          if(item.key == "miniTwoCodeUrl") {
            this.opt.columns.splice(index, 1);
          }
        })
      }
      this.onclickProvince();
      //获取房源
      this.getApartmentList();
      //获取房东列表
      this.getLandlordList();
    },
    methods: {
      onGet(opt) {
        let apartmentObj = {};
        let _this = this;
        if (opt.searchForm.apartmentId) {
          apartmentObj = _this.apartmentList.find((item) => {
            return item.label === opt.searchForm.apartmentId; //筛选出匹配数据
          });
        }
        var dto = {
          pageNumber: opt.skip,
          pageSize: opt.limit,
          name: apartmentObj.label,
          province: opt.searchForm.province,
          city: opt.searchForm.city,
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            (opt.searchForm.landlordId ? opt.searchForm.landlordId : null) : this.cache.getLS("userInfo")["userId"],
        };
        this.post("landlord-service/apartment/queryPagesApartment", dto, {
          isUseResponse: true
        }).then(res => {
          res.data.data.forEach(item => {
            item.cityStr = item.city == null ? "无" : item.city;
            item.typeStr = TYPE[item.type];
          });
          opt.cb(res.data);
        });
      },
      onclickProvince() {
        this.post("/landlord-service/base/getProvinces").then(res => {
          for (let i = 0; i < this.opt.search.length; i++) {
            let data = this.opt.search[i];
            if (data.key === "province") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              break;
            }
          }
        });
      },
      // 获取城市列表
      getCityList(name, cityName) {
        this.post("/landlord-service/base/getCitys?name=" + name + '&cityName=' + cityName).then(res => {
          for (let i = 0; i < this.opt.search.length; i++) {
            let data = this.opt.search[i];
            if (data.key === "city") {
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              data.opt.list = res;
              break;
            }
          }
        });
      },
      //获取房源列表
      getApartmentList(data,province) {
        var dto = {
          pageNumber: "1",
          pageSize: "99999",
          landlordId: this.cache.getLS("userInfo")["roleType"] == "1" ?
            null : this.cache.getLS("userInfo")["userId"],
          city: data,
          province: province
        };
        return new Promise((resolve, reject) => {
          this.post("landlord-service/apartment/queryApartmentInfo", dto).then(res => {
            if(res) {
              let returnData = {};
              res.forEach(data => {
                returnData[data.id] = data.name;
              });
              res = JSON.parse(JSON.stringify(res).replace(/name/g, "label"));
              res = JSON.parse(JSON.stringify(res).replace(/id/g, "value"));
              this.opt.search[2]["opt"]["list"] = res;
              this.apartmentList = res;
              resolve(returnData);
            }else {
              this.opt.search[2]["opt"]["list"] = res;
              this.apartmentList = res;
            }
            
          });
        });
      },
      //获取房东
      getLandlordList() {
        this.get('/landlord-service/apartment/queryLandlordInfo', {}).then(res => {
          res = JSON.parse(JSON.stringify(res).replace(/userName/g, "label"));
          res = JSON.parse(JSON.stringify(res).replace(/userId/g, "value"));
          this.opt.search.forEach(item => {
            if (item.key == "landlordId") {
              item.opt.list = res
            }
          })
        })
      }
    }
  };
</script>

<style lang="scss" scope>
  .el-message-box {
    max-width: 780px;
    min-width: 460px;
  }

  .statement-detail .el-col-12 {
    height: 90px !important;
  }
</style>